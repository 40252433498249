import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

import toast from "@/utils/toast";
import bedStoreModule from '../bedStoreModule';

export default function useBedModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'bed';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, bedStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const priceLocal = computed(() => itemLocal.value.price);
  const room = computed(() => itemLocal.value.room);

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      apartmentId: itemLocal.value.apartment.id,
      roomId: itemLocal.value.room.id,
      name: itemLocal.value.name,
      price: itemLocal.value.price,
      deposit: itemLocal.value.deposit,
      active: itemLocal.value.active,
    };

    isSubmitting.value = true;
    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('bed/updateBed', data)
        .then(() => { })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('bed/createBed', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  watch(priceLocal, val => {
    if (itemLocal.value.id === 0) {
      itemLocal.value.deposit = val;
    }
  });

  watch(room, val => {
    if (val && (!itemLocal.value.id || itemLocal.value.id === 0)) {
      itemLocal.value.price = val.price;
      itemLocal.value.deposit = val.deposit;
    }
  });

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
